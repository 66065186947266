import React from 'react';
import * as boolean from './polygon/boolean'
import * as merge from './polygon/merge'
import * as sandbox from './polygon/sandbox'
import * as adventure from './polygon/adventure'
import * as short from './polygon/short'
import * as correct from './polygon/correct'
import * as memory from './polygon/memory'
import { Link, Route, Routes } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';


let list = {
    memory: memory,
    correct: correct,
    short: short,
    boolean: boolean,
    merge: merge,
    sandbox: sandbox,
    adventure: adventure
}
class App extends React.Component {
    state = {}

    render() {


        return (<>
            <Routes>

                <Route path={'/'} Component={() => <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>{Object.entries(list).map(([k, v]) => <Link style={{ margin: 10 }} to={k}><v.Icon key={k} /></Link>)}</div>} />
                {Object.entries(list).map(([k, v]) =>
                    <Route path={'/' + k} Component={() => (<>
                        <div><Link to={'/polygon'}><Button onClick={() => window.history.back()}><ArrowLeftOutlined /></Button></Link><v.Icon /></div>
                        <v.Component />
                    </>)}
                    />
                )}
            </Routes>
        </>);
    }
}

export default App;
