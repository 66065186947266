type EventMap = Record<string, any>;

type EventKey<T extends EventMap> = string & keyof T;
type EventReceiver<T> = (params: T, other?: any) => void;


// `listeners` are unbounded -- don't use this in practice!
export default class eventEmitter<T extends EventMap>{
    listeners: {
        [K in keyof EventMap]?: Array<(p: EventMap[K], other?: any) => void>;
    } = {};

    on<K extends EventKey<T>>(key: K, fn: EventReceiver<T[K]>) {
        this.listeners[key] = (this.listeners[key] || []).concat(fn);
    }
    off<K extends EventKey<T>>(key: K, fn: EventReceiver<T[K]>) {
        this.listeners[key] = (this.listeners[key] || []).filter(f => f !== fn);
    }
    emit = this._dispatchEvent
    _dispatchEvent<K extends EventKey<T>>(key: K, data: T[K], other?: any) {
        (this.listeners[key] || []).forEach(function (fn) {
            fn(data, other);
        });
    }

}