import React from 'react';
import './App.css';
import { Button, Space, Layout, Modal } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { DeploymentUnitOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons'
import Settings from './settings/index'
import Chat from './chat/index'
import Polygon from './Polygon'
import Auth from './components/Auth'
import { Typography } from 'antd';
import Avatar from './components/Avatar'
import {
  Route,
  Link,
  BrowserRouter,
  Routes,
} from "react-router-dom";


declare global {
  interface Window {
    appSession: any
  }
}

window.appSession = JSON.parse((decodeURIComponent(document.cookie.split(';').find(t => t.split('=')[0].trim() === 'jwt') || '') || 'jwt={"email_verified":false}').split('=').slice(1).join('='))
const { Text } = Typography;

class App extends React.Component {
  state = {
    loading: false,
    text: '',
    local: '',
    error: null,

  }
  _destroyedc: boolean = false
  modal?: ReturnType<typeof Modal.info>
  async componentDidMount() {

    this._destroyedc = false


    if (this._destroyedc) { return; }


  }
  componentWillUnmount(): void {
    this.modal && this.modal.destroy()
    this._destroyedc = true
  }
  render() {

    let page = window.location.pathname.split('/')[1] || 'chat'

    let info: any = window.appSession;
    let routes;
    if (!info.email_verified) {
      routes = <Routes>
        <Route path='/*' Component={Auth} />
      </Routes>

    } else {
      routes = <Routes>
        <Route path='/*' Component={Chat} />
        <Route path='/polygon/*' Component={Polygon} />
        <Route path='/settings/*' Component={Settings} />
      </Routes>
    }
    return (<BrowserRouter>
      <Space direction="vertical" style={{ width: '100%', height: '100vh' }} >
        <Layout style={{ height: '100vh' }}>
          <Header style={{ background: '#000000', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}><h1 style={{ margin: 0 }}>PAILA</h1> <Avatar /></Header>
          <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }} >
            {routes}
          </Content>
          <Footer style={{ padding: 0, display: 'flex', justifyContent: 'space-between' }}>
            <Link style={{ width: '-webkit-fill-available' }} onClick={() => { this.forceUpdate() }} to={`/`}>
              <Button style={{ width: '-webkit-fill-available', borderBottom: page === 'chat' ? '3px solid #f5b72e' : undefined }} type="text"  ><Text style={{ color: page === 'chat' ? '#f5b72e' : undefined }}><MailOutlined /> Чат</Text></Button>
            </Link>
            <Link style={{ width: '-webkit-fill-available' }} onClick={() => { this.forceUpdate() }} to={`/polygon`}>
              <Button style={{ width: '-webkit-fill-available', borderBottom: page === 'polygon' ? '3px solid #f5b72e' : undefined }} type="text" ><Text style={{ color: page === 'polygon' ? '#f5b72e' : undefined }}><DeploymentUnitOutlined /> Полигон</Text></Button>
            </Link>
            <Link style={{ width: '-webkit-fill-available' }} onClick={() => { this.forceUpdate() }} to={`/settings`}>
              <Button style={{ width: '-webkit-fill-available', borderBottom: page === 'settings' ? '3px solid #f5b72e' : undefined }} type="text"><Text style={{ color: page === 'settings' ? '#f5b72e' : undefined }}><SettingOutlined /> Настройки</Text></Button>
            </Link>
          </Footer>
        </Layout ></Space >
    </BrowserRouter>
    );
  }
}

export default App;
