import React from 'react';
import { Input, Button, Space, Result, Menu, Select, message } from 'antd'
import { CommentOutlined, SendOutlined } from '@ant-design/icons'
import { Alert, notification } from 'antd';
import generate, { ChatItem } from './../tools/generate'
import ChatList from '../components/ChatList';

const { TextArea } = Input;

class App extends React.Component {
    state = {
        _id: undefined as undefined | string,
        name: '',
        loading: false,
        data: [] as ChatItem[],
        text: '',
        error: null,
        historyList: [] as { value: string, label: string }[],
        cancel: null as null | (() => void)
    }
    componentDidMount(): void {
        this.loadHistoryList();
    }
    loadHistoryList = async () => {
        try {
            this.setState({ loading: true });
            let res = await fetch('/api/history/get', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({ filter: {}, sort: { date: -1 }, project: { _id: 1, name: 1 } })
            })


            let data = await res.json() as any[]
            this.setState({ historyList: data.map(v => ({ value: v._id, label: v.name })), loading: false })
        } catch (err) {
            this.setState({ loading: false })
            throw err;
        }
    }
    loadHistory = async (_id: string) => {
        let res = await fetch('/api/history/get', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ filter: { _id }, limit: 1 })
        })


        let data = (await res.json())[0];

        this.setState({ data: data.data, name: data.name, _id: data._id })

    }
    saveHistory = async (_id: string | undefined, name: string, data: ChatItem[]) => {
        let res = await fetch('/api/history/update', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ _id: _id, name, data })
        })

        let error = await res.text()
        if (error) {
            message.error('При сохранении истории произошла ошибка: ' + error)
        }
    }

    deleteHistory = async (_id: string) => {
        let res = await fetch('/api/history/delete', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ _id: _id })
        })

        let error = await res.text()
        if (error) {
            throw new Error('При удалении чата произошла ошибка: ' + error)
        }
    }
    onSend = async () => {
        let data = this.state.data
        let current = data[data.length - 1]
        let cancels: (() => void)[] = [];
        let element = document.getElementById('chatList')
        let currentScrollHeight = element?.scrollHeight || 0
        let currentHeight = element?.clientHeight || 0
        let scrollTop = element && element.scrollTop || 0
        try {
            this.setState({ loading: true, error: null, data, text: '', cancel: () => cancels.forEach(fn => fn()) }, () => {

                let el = element
                if (el && ((scrollTop + currentHeight) / currentScrollHeight) > 0.95) {
                    el.scrollTo(0, element?.scrollHeight || 0)
                }
            })

            current.loading = true
            await generate('default', data, cancels, () => this.forceUpdate())

            delete current.loading

            if (!this.state.name) {
                let controller = new AbortController();
                let mini = this.state.data.slice(0);
                mini.push({ role: 'user', text: 'О чем идет текущая беседа? Напиши краткое предложение.' });
                mini.push({ role: 'bot', text: 'Краткая текущая беседа: "' });

                await generate('default', mini, cancels, () => { this.state.name = mini[mini.length - 1].text.trim().slice('Краткая текущая беседа: "'.length); this.forceUpdate() })
                this.state.name = mini[mini.length - 1].text.trim().slice('Краткая текущая беседа: "'.length, -1)

            }

            await this.saveHistory(this.state._id, this.state.name, this.state.data)
            this.forceUpdate()
        } catch (err) {
            delete current.loading
            let message = (err as any).message as string
            if (message.indexOf('aborted') > -1) {
                message = ''
            } else {

                notification.error({ message })
            }
            data.pop()
            let text = data[data.length - 1].text

            data.pop()
            this.setState({ error: message, text })
        }
        this.setState({ loading: false, cancel: null })

    }

    render() {
        return (<>
            <div style={{ display: 'flex', flexDirection: 'row' }}><Select
                style={{ width: '-webkit-fill-available' }}
                showSearch
                filterOption={(e, e2) => e2 && (e2.label || '').toLowerCase().indexOf(e.toLowerCase()) > -1 || false}
                value={this.state.name}
                options={this.state.historyList}
                onSelect={(e) => { this.loadHistory(e) }}
            />
                {this.state._id && <Button onClick={async () => {
                    try {
                        this.state._id && await this.deleteHistory(this.state._id);
                        await this.loadHistoryList()
                        this.setState({ _id: undefined, name: '', data: [] })
                    } catch (err) {
                        message.error((err as any).message)
                    }
                }}>Удалить чат</Button>}
            </div>
            {this.state.error && <Alert message={this.state.error} type="error" ></Alert>}
            {this.state.data.length ? <ChatList data={this.state.data} /> : <Result
                style={{ marginBottom: 'auto', }}
                icon={<CommentOutlined style={{ color: '#ffffff73' }} />}
                title=""
                subTitle="Задайте вопрос"

            />}
            <Space.Compact style={{ width: '95%', margin: '0px auto 5px' }}>
                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.state.data[this.state.data.length - 1].text = ''
                    this.onSend()
                }}>Перегенерация</Button> : null}

                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.onSend()
                }}>Продолжить</Button> : null}
                {this.state.cancel && <Button style={{ width: '200px', margin: '12px 0px' }} onClick={this.state.cancel}>Стоп</Button>}
            </Space.Compact>
            <Space.Compact style={{ width: '95%', margin: '0px auto 5px' }}>
                <Input.Search placeholder='Введите запрос' value={this.state.text} loading={this.state.loading}
                    onChange={(e) => this.setState({ text: e.target.value })} onSearch={() => {

                        this.state.data.push({ text: this.state.text, role: 'user' })
                        this.state.data.push({ text: '', role: 'bot' })
                        this.onSend()
                    }} enterButton={<SendOutlined />} />

            </Space.Compact>
        </>);
    }
}

export default App;
