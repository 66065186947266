import React from 'react';
import Card from 'antd/es/card';
import Status from './serverStatus'
import Table from 'antd/es/table';



export default class Settings extends React.Component {

    state = {
        data: [] as any[]
    }
    componentDidMount(): void {
        this.loadLogs()
    }
    loadLogs = async () => {
        let res = await fetch('/api/logs/get', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ filter: {}, limit: 100, sort: { date: -1 } })
        })


        let data = await res.json()

        this.setState({ data })
    }
    render() {

        let table = null;

        if (window.appSession.email) {
            table = <Table size='small' columns={[{ dataIndex: '_id', }, { dataIndex: 'url' }, { dataIndex: 'user' }, { dataIndex: 'date', render: (v) => new Date(v).toISOString() }]} dataSource={this.state.data}></Table>
        }
        return <>
            <Card><Status /></Card>
            {table}
        </>
    }
}