


import React from 'react';
import { Input, Button, Space } from 'antd'
import { Alert, notification } from 'antd';
import generate, { ChatItem } from './../tools/generate';
import ChatList from '../components/ChatList';
import { CodeSandboxOutlined, SendOutlined } from '@ant-design/icons';


export let Icon = () => <Button ><CodeSandboxOutlined /> Приключение</Button>

export class Component extends React.Component {
    state = {
        loading: false,
        data: [
            //  { role: 'system', text: 'Ты мастер игры в DnD, который описывает окружение игрока и варианты действий. Роль "Окружение:" описывает окружение игрока. Роль "Действия:" описывает 5 коротких вариантов игрока.' },
            { role: 'bot', text: 'Приветствую путник. Это начало путешествия. Кто ты?' }
        ] as ChatItem[],
        text: '',
        actions: [] as string[],
        error: null,
        cancel: null as null | (() => void)
    }

    onSend = async (role: string = 'user') => {

        let startData = JSON.parse(JSON.stringify(this.state.data))
        let startText = this.state.text
        this.state.data.push({ text: this.state.text, role: role })
        this.state.data.push({ text: 'Ты мастер игры в ДнД, который описывает окружение игрока.', role: 'system', hidden: true })
        this.state.data.push({ text: 'Опиши текущее окружение игрока. Пиши осмысленно и без ошибок. Не добавляй ничего лишнего.', role: 'user', hidden: true })
        this.state.data.push({ text: '', role: 'bot' })
        let data = this.state.data

        let cancels: (() => void)[] = [];
        let element = document.getElementById('chatList')
        let currentScrollHeight = element?.scrollHeight || 0
        let currentHeight = element?.clientHeight || 0
        let scrollTop = element && element.scrollTop || 0
        try {
            this.setState({ loading: true, error: null, data, text: '', cancel: () => cancels.forEach(fn => fn()) }, () => {

                let el = element
                if (el && ((scrollTop + currentHeight) / currentScrollHeight) > 0.95) {
                    el.scrollTo(0, element?.scrollHeight || 0)
                }
            })
            let continueText = async (data: ChatItem[]) => {
                let current = data[data.length - 1]
                current.loading = true
                await generate('sandbox', data, cancels, () => this.forceUpdate())


                delete current.loading
            }
            //data[0].text = 'Ты мастер игры в DnD, который описывает окружение игрока. Роль "Окружение:" описывает окружение игрока.'
            await continueText(data)
            let oldText = data[data.length - 1].text;
            data[data.length - 1].text = '';
            let correctionData = [
                { role: 'system', text: 'Ты бот, который переписывает исправленное предложение на русском языке. Не добавляй ничего лишнего.' },
                { role: 'user', text: oldText },
                data[data.length - 1]
            ]
            await continueText(correctionData)
            this.state.data.splice(-3, 2)
            this.state.data.push({ text: 'Ты мастер игры в ДнД, который описывает варианты действий игрока. Описывает 5 коротких вариантов игрока в текущей местности.', role: 'system', hidden: true })
            this.state.data.push({ text: 'Опиши возможные варианты действий игрока в данной местности в виде списка.', role: 'user', hidden: true, })
            this.state.data.push({ text: '1.', role: 'bot' })
            //data[0].text = 'Ты мастер игры в DnD, который описывает варианты действий игрока. Роль "Действия:" описывает 5 коротких вариантов игрока.'

            await continueText(data)
            this.state.data = this.state.data.slice(0, -3)
            this.state.actions = data[data.length - 1].text.split('\n').filter(v => v).map(v => v.split('.').slice(1).join('.'))
            console.log('actions :', this.state.actions);

            this.forceUpdate()
        } catch (err) {
            let message = (err as any).message as string
            if (message.indexOf('aborted') > -1) {
                message = ''
            } else {

                notification.error({ message })
            }
            this.setState({ error: message, text: startText, data: startData })
        }
        this.setState({ loading: false, cancel: null })

    }
    render() {
        return (<>
            {this.state.error && <Alert message={this.state.error} type="error" ></Alert>}
            <ChatList data={this.state.data} />
            <div style={{ display: 'flex', flexDirection: 'column', width: '95%', margin: '0px auto 5px' }}>
                {this.state.actions.map(act => <Button disabled={this.state.loading} style={{ margin: '6px 0px' }}
                    onClick={() => {
                        this.setState({ text: act }, () => { this.onSend('user') })
                    }}
                >{act}</Button>)}
            </div>
            <Space.Compact style={{ width: '95%', margin: '0px auto 5px' }}>
                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.state.data[this.state.data.length - 1].text = ''
                    this.onSend()
                }}>Перегенерация</Button> : null}

                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.onSend()
                }}>Продолжить</Button> : null}
                {this.state.cancel && <Button style={{ width: '200px', margin: '12px 0px' }} onClick={this.state.cancel}>Стоп</Button>}
            </Space.Compact>
            <div style={{ width: '95%', margin: '0px auto 5px', flexWrap: 'wrap' }}>
                <Input.Search placeholder='Введите запрос' value={this.state.text} loading={this.state.loading}
                    onChange={(e) => this.setState({ text: e.target.value })} onSearch={() => {


                        this.onSend()
                    }} enterButton={<SendOutlined />} />
            </div>
        </>);
    }
}
