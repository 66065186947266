


import React from 'react';
import { Input, Button, Space } from 'antd'
import { Alert, notification } from 'antd';
import generate, { ChatItem } from './../tools/generate';
import ChatList from '../components/ChatList';
import { CodeSandboxOutlined, SendOutlined } from '@ant-design/icons';

let { TextArea } = Input;
export let Icon = () => <Button ><CodeSandboxOutlined />Долгая память</Button>
let originalSystem = 'Ты автономный бот, который служит пользователю и отвечает на вопросы. "Контекст" Это список утверждений которые были записаны в память. Используй их если они помогут.'
export class Component extends React.Component {
    state = {
        loading: false,
        data: [
            //  { role: 'system', text: 'Ты мастер игры в DnD, который описывает окружение игрока и варианты действий. Роль "Окружение:" описывает окружение игрока. Роль "Действия:" описывает 5 коротких вариантов игрока.' },
            { role: 'system', text: originalSystem }
        ] as ChatItem[],
        //memory: ["Пользователя зовут Вася", "Нужно стремиться удовлетворять пользователя", "Биткоин стоит 29 000 долларов США."] as string[],
        memory: [] as string[],
        text: '',
        error: null,
        cancel: null as null | (() => void)
    }

    onSend = async (role: string = 'user') => {

        let startData = JSON.parse(JSON.stringify(this.state.data))
        let startText = this.state.text

        let data = this.state.data

        let cancels: (() => void)[] = [];
        let element = document.getElementById('chatList')
        let currentScrollHeight = element?.scrollHeight || 0
        let currentHeight = element?.clientHeight || 0
        let scrollTop = element && element.scrollTop || 0
        try {
            this.setState({ loading: true, error: null, data, text: '', cancel: () => cancels.forEach(fn => fn()) }, () => {

                let el = element
                if (el && ((scrollTop + currentHeight) / currentScrollHeight) > 0.95) {
                    el.scrollTo(0, element?.scrollHeight || 0)
                }
            })
            let continueText = async (data: ChatItem[]) => {
                let current = data[data.length - 1]
                current.loading = true
                await generate('sandbox', data, cancels, () => this.forceUpdate())

                delete current.loading
            }

            this.state.data.push({ text: this.state.text, role: role })
            this.state.data[0].text = 'Ты бот, который определяет новую для себя информацию для добавления в долгосрочный контекст. Не пиши ничего лишнего. Пиши только "долгосрочная информация для контекста." если появилась информация, которого бот не знает и "ничего нового." если дополнительной информации нет.'

            let context = 'Контекст:\n' + this.state.memory.map((v, i) => (i + 1) + '. ' + v).join('\n')
            this.state.data.push({ text: context + '\nВход: Можно ли извлечь новую информацию на долгий период в контекст из предложения "' + startText + '"? Пиши только "долгосрочная информация для контекста." или "ничего нового."', role: 'system' })
            this.state.data.push({ text: 'Выход:', role: 'bot' })
            await continueText(this.state.data)
            let addedInfo = false;

            if (true) {
                this.state.data[0].text = 'Ты бот, который определяет новую для себя информацию. Не пиши ничего лишнего. Пиши только ту информацию, которую не знает бот на основе диалога.'
                this.state.data = this.state.data.slice(0, -2)
                this.state.data.push({ text: context + `\nПорассуждай над сообщением, который написал пользователь и напиши 5 аргументов списком, которых нет в контектсе. Вход: "` + startText + '"', role: 'system' })
                this.state.data.push({ text: 'Рассуждение. Выход:\n1.', role: 'bot' })
                await continueText(this.state.data)
                //let newInfo = this.state.data[this.state.data.length - 1].text.slice('Рассуждение. Выход: "'.length).trim()
                //if (newInfo.slice(-2) === '".') { newInfo = newInfo.slice(0, -2) } else if (newInfo.slice(-1) === '"') { newInfo = newInfo.slice(0, -1) }
                //this.state.memory.unshift(newInfo)
                let newInfo = this.state.data[this.state.data.length - 1].text.split('\n').filter(v => v.split('.').length && Number(v.split('.')[0])).map(v => v.split('.').slice(1).join('.').trim())
                this.state.memory = newInfo.concat(this.state.memory)
                this.state.memory = Object.keys(this.state.memory.reduce((c, v) => { c[v] = 1; return c }, {} as { [k: string]: 1 }))
                this.state.data = this.state.data.slice(0, -2)
                addedInfo = true;
            } else {
                this.state.data = this.state.data.slice(0, -2)
            }
            context = 'Контекст:\n' + this.state.memory.map((v, i) => (i + 1) + '. ' + v).join('\n')
            // this.state.data[0].text = 'Ты бот, который фильтрует нужную информацию.'


            //this.state.data.push({ text: context + '\n\nПерепиши список что бы осталась только нужная информация для ответа. Отбрасывай лишнее и переписывай нужное. Вход: "' + startText + '"', role: 'system' })
            //this.state.data.push({ text: 'Контекст:\n1.', role: 'bot' })
            //await continueText(this.state.data)
            //context = this.state.data[this.state.data.length - 1].text
            //this.state.data = this.state.data.slice(0, -2)


            this.state.data[0].text = originalSystem
            this.state.data.push({ text: 'Контекст:\n' + context.slice('Контекст:\n'.length) + '\nКонец контекста\n\nНапиши 5 вариантов ответов на предложение "' + startText + '". "Контекст" - это список утверждений которые были записаны в память. Используй Контекст только если они помогут.', role: 'system' })
            this.state.data.push({ text: 'Выход:\n\n1.', role: 'bot' })
            await continueText(this.state.data)
            let answers = this.state.data[this.state.data.length - 1].text.slice('Выход:\n\n'.length).trim()
            this.state.data = this.state.data.slice(0, -2)

            this.state.data.push({ text: 'Варианты ответов:\n' + answers + '\n\nИз 5 вариантов ответов перепиши лучший вариант ответа.', role: 'system' })
            this.state.data.push({ text: 'Выход: "', role: 'bot' })
            await continueText(this.state.data)
            this.state.data.splice(-2, 1)
            console.log('')
            this.state.data[this.state.data.length - 1].text = this.state.data[this.state.data.length - 1].text.slice('Выход: "'.length).trim()
            if (this.state.data[this.state.data.length - 1].text.slice(-1) === '"') this.state.data[this.state.data.length - 1].text = this.state.data[this.state.data.length - 1].text.slice(0, -1)

            if (addedInfo) {
                context = 'Контекст:\n' + this.state.memory.map((v, i) => (i + 1) + '. ' + v).join('\n')
                this.state.data[0].text = 'Ты бот, который приводит в порядок контекст. Перепиши список контекста правильно, без дубликатов и противоречивой информации.'
                this.state.data.push({ text: context + '\n\nПерепиши список контекста правильно, исправляя факты, без дубликатов и противоречивой информации.', role: 'system' })
                this.state.data.push({ text: 'Правильный список контекста:\n 1.', role: 'bot' })
                await continueText(this.state.data)
                this.state.memory = this.state.data[this.state.data.length - 1].text.split('\n').filter(v => v.split('.').length && Number(v.split('.')[0])).map(v => v.split('.').slice(1).join('.').trim())
                this.state.data = this.state.data.slice(0, -2)
            }






            // let oldText = data[data.length - 1].text;
            // data[data.length - 1].text = '';
            // let correctionData = [
            //     { role: 'system', text: 'Ты бот, который переписывает исправленное предложение на русском языке. Не добавляй ничего лишнего.' },
            //     { role: 'user', text: oldText },
            //     data[data.length - 1]
            // ]
            // await continueText(correctionData)
            // this.state.data.splice(-3, 2)
            // this.state.data.push({ text: 'Ты мастер игры в ДнД, который описывает варианты действий игрока. Описывает 5 коротких вариантов игрока в текущей местности.', role: 'system', hidden: true })
            // this.state.data.push({ text: 'Опиши возможные варианты действий игрока в данной местности в виде списка.', role: 'user', hidden: true, })
            // this.state.data.push({ text: '1.', role: 'bot' })
            // await continueText(data)
            // this.state.data = this.state.data.slice(0, -3)

            this.forceUpdate()
        } catch (err) {
            let message = (err as any).message as string
            if (message.indexOf('aborted') > -1) {
                message = ''
            } else {

                notification.error({ message })
            }
            this.setState({ error: message, text: startText, data: startData })
        }
        this.setState({ loading: false, cancel: null })

    }
    render() {
        return (<>
            {this.state.error && <Alert message={this.state.error} type="error" ></Alert>}
            <ChatList data={this.state.data} />
            <Input style={{ display: 'flex', flexDirection: 'column', width: '95%', margin: '0px auto 5px' }} value={JSON.stringify(this.state.memory)} onChange={(e) => this.setState({ memory: JSON.parse(e.target.value) })}></Input>
            <Space.Compact style={{ width: '95%', margin: '0px auto 5px' }}>
                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.state.data[this.state.data.length - 1].text = ''
                    this.onSend()
                }}>Перегенерация</Button> : null}

                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.onSend()
                }}>Продолжить</Button> : null}
                {this.state.cancel && <Button style={{ width: '200px', margin: '12px 0px' }} onClick={this.state.cancel}>Стоп</Button>}
            </Space.Compact>
            <div style={{ width: '95%', margin: '0px auto 5px', flexWrap: 'wrap' }}>
                <Input.Search multiple placeholder='Введите запрос' value={this.state.text} loading={this.state.loading}
                    onChange={(e) => this.setState({ text: e.target.value })} onSearch={() => {


                        this.onSend()
                    }} enterButton={<SendOutlined />} />
            </div>
        </>);
    }
}
