import { UserOutlined } from '@ant-design/icons';
import Avatar from 'antd/es/avatar';
import Dropdown from 'antd/es/dropdown';
import { MenuProps } from 'antd/es/menu';
import React from 'react';

function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

const items: MenuProps['items'] = [
    {
        key: '1',
        label: (
            <a onClick={() => { deleteAllCookies(); window.location.reload(); }}>
                Выйти
            </a>
        ),
    },

];


export default class Settings extends React.Component {

    render() {

        return <Dropdown menu={{ items }}>
            <Avatar style={{ margin: 'auto 0' }} icon={window.appSession.picture ? <img src={window.appSession.picture} /> : <UserOutlined />} />
        </Dropdown>
    }
}