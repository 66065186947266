import { getBson } from './bson'
export type ChatItem = { text: string, role: string, loading?: true, hidden?: true, queue?: number }

console.log(getBson);
type SystemPromts = 'default' | 'boolean' | 'merge' | 'sandbox'
export default async function (type: SystemPromts, data: ChatItem[], cancelArr: (() => void)[], onUpdate: () => void) {


    let controller = new AbortController();
    cancelArr.push(() => {
        controller.abort()
    })
    let res = await fetch('/api/generate/' + type, { headers: { 'Content-type': 'application/json' }, method: 'POST', signal: controller.signal, body: JSON.stringify(data) });

    if (res.status !== 200) {
        throw new Error(await res.text())
    }

    let eventEmitter = res.bsonParser()
    cancelArr.pop();
    cancelArr.push(eventEmitter.cancel)
    await new Promise<void>((resolve, reject) => {
        eventEmitter.on('data', (doc) => {
            console.log('doc: ', doc);
            if (doc.content) {
                data[data.length - 1].text += doc.content;
            }
            if (typeof doc.index === 'number') {
                data[data.length - 1].queue = doc.index;
            }
            onUpdate()
        })
        eventEmitter.on('error', (err) => {
            reject(err)
        })
        eventEmitter.on('end', () => {
            resolve()
        })
    })

    cancelArr.pop();

}