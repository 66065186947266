


import React from 'react';
import { Input, Button, Space, Layout, Result, Spin } from 'antd'
import { SendOutlined, CodeSandboxOutlined } from '@ant-design/icons'
import { Alert, notification } from 'antd';
import generate, { ChatItem } from './../tools/generate'
import ChatList from '../components/ChatList';


let { TextArea } = Input
export let Icon = () => <Button ><CodeSandboxOutlined /> Песочница</Button>

export class Component extends React.Component {
    state = {
        loading: false,
        data: [{ role: 'system', text: 'Ты бот который отвечает на вопросы пользователя' }] as ChatItem[],
        role: 'user',
        brole: 'bot',
        bottext: '',
        text: '',
        error: null,
        cancel: null as null | (() => void)
    }

    onSend = async () => {
        let data = this.state.data
        let current = data[data.length - 1]
        let cancels: (() => void)[] = [];
        let element = document.getElementById('chatList')
        let currentScrollHeight = element?.scrollHeight || 0
        let currentHeight = element?.clientHeight || 0
        let scrollTop = element && element.scrollTop || 0
        try {
            this.setState({ loading: true, error: null, data, text: '', cancel: () => cancels.forEach(fn => fn()) }, () => {

                let el = element
                if (el && ((scrollTop + currentHeight) / currentScrollHeight) > 0.95) {
                    el.scrollTo(0, element?.scrollHeight || 0)
                }
            })

            current.loading = true
            await generate('sandbox', data, cancels, () => this.forceUpdate())

            delete current.loading
            this.forceUpdate()
        } catch (err) {
            delete current.loading
            let message = (err as any).message as string
            if (message.indexOf('aborted') > -1) {
                message = ''
            } else {

                notification.error({ message })
            }
            data.pop()
            let text = data[data.length - 1].text
            data.pop()
            this.setState({ error: message, text })
        }
        this.setState({ loading: false, cancel: null })

    }
    render() {
        return (<>
            {this.state.error && <Alert message={this.state.error} type="error" ></Alert>}
            <ChatList data={this.state.data} />
            <Space.Compact style={{ width: '95%', margin: '0px auto 5px' }}>
                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.state.data[this.state.data.length - 1].text = ''
                    this.onSend()
                }}>Перегенерация</Button> : null}

                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.onSend()
                }}>Продолжить</Button> : null}
                {this.state.cancel && <Button style={{ width: '200px', margin: '12px 0px' }} onClick={this.state.cancel}>Стоп</Button>}
            </Space.Compact>
            <div style={{ width: '95%', margin: '0px auto 5px', flexWrap: 'wrap' }}>
                <TextArea placeholder='Системное сообщение' value={this.state.data[0].text} onChange={(e) => {
                    let data = this.state.data
                    data[0].text = e.target.value
                    this.setState({ data })
                }
                } />
                <Input placeholder='Роль бота' value={this.state.brole} onChange={(e) => this.setState({ brole: e.target.value })} />
                <Input placeholder='Роль' value={this.state.role} onChange={(e) => this.setState({ role: e.target.value })} />
                <TextArea placeholder='Текст бота' value={this.state.bottext} onChange={(e) => this.setState({ bottext: e.target.value })} />
                <TextArea placeholder='Введите текст' value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} />
                <Button icon={<SendOutlined />} onClick={() => {
                    this.state.data.push({ text: this.state.text, role: this.state.role })
                    this.state.data.push({ text: this.state.bottext, role: this.state.brole })
                    this.onSend()
                }}>Отправить</Button>
            </div>
        </>);
    }
}
