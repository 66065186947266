import bson, { Document } from 'bson';
import EventEmitter from './eventEmmiter'

console.log('bson: ', bson);
declare global {
    interface Response {
        bsonParser: () => EventEmitter<{ data: Document, error: Error, end: true }> & { cancel: () => void }
        bson: () => Promise<Document[]>
    }
}
let errMsg = (e: unknown, addMessage?: string) => {
    let message: string;

    if (typeof e === 'string') {
        message = e;
    } else if (e instanceof Error) {
        message = e.message;
    } else if (!e) {
        if (typeof addMessage === 'string') {
            message = addMessage
        } else {
            message = 'Произошла непредвиденная ошибка'
        }
    } else if (e instanceof ErrorEvent) {
        message = e.message;
    } else if (typeof (e as any).error === 'string') {
        message = (e as any).error;
    } else {
        message = JSON.stringify(e)
    }
    return message;
}

export async function getJson(url: string, data?: any, params: { method: "GET" | "POST", credentials: 'include' | 'omit' } = { method: "POST", credentials: 'include' }) {
    let response;
    if (data) {
        response = await fetch(url, {
            method: params.method || 'POST',
            credentials: params.credentials || 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    } else {
        response = await fetch(url, {
            credentials: 'include',
        });
    }

    if (response.status > 200) {
        let message = await response.text();

        try {
            message = JSON.parse(message).message;
        } catch (e) {
            //empty
        }
        throw new Error(message || 'Не удалось выполнить запрос. Код ошибки: ' + response.status);
    } else {
        let res = await response.json();
        return res;
    }
}



(new Response({} as any) as any).__proto__.bson = async function () {
    let _this = this as Response;
    if (!_this.arrayBuffer) {
        throw new Error('Ваш браузер устарел и не поддерживает загрузку в бинарном формате. Обновите браузер и повторите попытку');
    }
    if (_this.status !== 200) {
        throw new Error((await this.text()) || ('Код ошибки:' + this.status));
    }
    let out = [];

    let data = new Uint8Array(await this.arrayBuffer());

    // eslint-disable-next-line no-constant-condition
    while (true) {
        if (data[0] !== undefined && data[1] !== undefined && data[2] !== undefined && data[3] !== undefined) {
            let size =
                data[0] | (data[1] << 8) | (data[2] << 16) | (data[3] << 24);

            if (size < 0) {
                let errorMessageSize =
                    (data[4] || 0) |
                    ((data[5] || 0) << 8) |
                    ((data[6] || 0) << 16) |
                    ((data[7] || 0) << 24);

                throw new Error(data.slice(8, 8 + errorMessageSize).toString());
            }

            if (size < 0) {
                let errorMessageSize =
                    (data[4] || 0) | ((data[5] || 0) << 8) | ((data[6] || 0) << 16) | ((data[7] || 0) << 24);

                //TODO on error
                data = data.subarray(8 + errorMessageSize);
                break;
            }
            if (data.length >= size) {
                out.push(bson.deserialize(new Uint8Array(data.subarray(0, size))));

                data = data.subarray(size, data.length);
            } else {
                break;
            }
        } else {
            break;
        }
    }

    return out;
};

(new Response({} as any) as any).__proto__.bsonParser = function () {
    let _this = this as Response;
    let emitter = new EventEmitter<{ error: Error, end: true, data: Document }>() as EventEmitter<{
        error: Error;
        end: true;
        data: Document;
    }> & { cancel: () => void };
    let reader: ReadableStreamDefaultReader<Uint8Array> | null;
    let cancel = false;
    emitter.cancel = () => {
        cancel = true;
        if (reader) {
            reader.cancel();
        }
    };
    if (_this.status !== 200) {
        _this.text().then(text => {
            emitter.emit('error', new Error(text || ('Код ошибки:' + _this.status)));
            emitter.emit('end', true);
        }).catch(err => {
            emitter.emit('error', new Error(err.message || ('Код ошибки:' + _this.status)));
            emitter.emit('end', true);
        });
        return emitter;
    }
    reader = _this.body && _this.body.getReader && _this.body.getReader() || null;
    let data = new Uint8Array(0);
    let run = async () => {
        if (reader) {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                let { value, done } = await reader.read();
                if (cancel) {
                    return reader.cancel();
                }
                if (done) {
                    return emitter.emit('end', true);
                } else {
                    let val = value as Uint8Array;
                    let oldData = data;
                    data = new Uint8Array(oldData.length + val.length);
                    data.set(oldData);
                    data.set(val, oldData.length);
                    try {

                        // eslint-disable-next-line no-constant-condition
                        while (true) {
                            if (data[0] !== undefined && data[1] !== undefined && data[2] !== undefined && data[3] !== undefined) {
                                let size =
                                    data[0] |
                                    (data[1] << 8) |
                                    (data[2] << 16) |
                                    (data[3] << 24);

                                if (size < 0) {
                                    let errorMessageSize =
                                        (data[4] || 0) |
                                        ((data[5] || 0) << 8) |
                                        ((data[6] || 0) << 16) |
                                        ((data[7] || 0) << 24);
                                    emitter.emit('error', new Error(new Uint8Array(data.slice(8, 8 + errorMessageSize)).toString()));
                                    emitter.emit('end', true);
                                    break;
                                }
                                if (data.length >= size) {
                                    let dd = data.subarray(0, size);
                                    if (dd.length > 5) {

                                        emitter.emit('data', bson.deserialize(new Uint8Array(dd)));
                                    }
                                    data = data.subarray(size);
                                } else {
                                    break;
                                }
                            } else {
                                break;
                            }
                        }
                    } catch (err) {
                        console.log('err', err);
                        reader.cancel();
                        emitter.emit('error', new Error(errMsg(err)));
                        emitter.emit('end', true);
                        return console.log('Непредвиденная ошибка');
                    }

                }
            }
        } else {
            try {
                let data = await _this.bson();
                data.forEach(dd => {
                    emitter.emit('data', dd);
                });
                emitter.emit('end', true);

            } catch (err) {
                emitter.emit('error', new Error(errMsg(err)));
                emitter.emit('end', true);
            }
        }
    };
    run();
    return emitter;
};

//(new Response({} as any) as any).__proto__.bsonParser = bsonParser

export async function getBson(url: string, data?: any, params: { method: "GET" | "POST", credentials?: 'include' | 'omit' } = { method: 'POST', credentials: 'include' }) {
    let response;
    response = await fetch(url, {
        method: params.method || 'POST',
        credentials: params.credentials || 'include',
        headers: {
            'Accept': 'application/bson',
            'Content-Type': 'application/bson',
        },
        body: bson.serialize(data || {}),
    });

    if (response.status > 200) {
        let message = await response.text();
        try {
            message = JSON.parse(message).message;
        } catch (e) {
            //empty
        }
        throw new Error(message || 'Произошла непредвиденная ошибка');
    } else {
        return await response.bson();
    }
}