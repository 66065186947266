import { Button } from 'antd';
import React from 'react';




export default class Settings extends React.Component {

    render() {

        return <Button onClick={() => { window.location.href = '/api/login' }}>Войти через Google</Button>
    }
}