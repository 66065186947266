import { ChatItem } from "../tools/generate"
import { CommentOutlined, LoadingOutlined } from '@ant-design/icons'
import { UserOutlined, RobotOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import { List, Avatar } from 'antd';

export default (props: { data: ChatItem[] }) => {
    if (props.data.length) {
        return <List id="chatList"
            style={{ marginBottom: 'auto', overflow: 'auto' }}
            itemLayout="horizontal"
            dataSource={props.data.filter(i => !i.hidden)}
            renderItem={(item, index) => {
                let icon = item.role === 'user' ? <UserOutlined /> : <RobotOutlined />

                if (item.loading) {
                    icon = <div style={{ position: 'relative' }}><LoadingOutlined spin style={{ fontSize: '30px', zIndex: 1, color: '#f5b72e', left: '0px', top: "0px", position: 'absolute' }} /><RobotOutlined style={{ left: '0px', top: "0px", margin: '6px', position: 'absolute' }} /></div>
                }
                let queue = null;
                if (typeof item.queue === 'number') {
                    queue = <div style={{ position: 'absolute', top: '-9px', fontSize: '10px', opacity: '0.5' }}>В очереди: {item.queue}</div>
                }
                return (<List.Item>
                    <List.Item.Meta
                        style={{ position: 'relative' }}
                        avatar={<Avatar style={{ marginLeft: '16px' }} icon={icon} />}
                        title={<>{queue}<div style={{ whiteSpace: 'break-spaces' }}>{item.text}</div></>}
                    />

                </List.Item>)
            }}
        />
    } else {
        return <Result
            style={{ marginBottom: 'auto', }}
            icon={<CommentOutlined style={{ color: '#ffffff73' }} />}
            title=""
            subTitle="Задайте вопрос"

        />
    }
}