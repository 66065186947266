


import React from 'react';
import { Input, Button, Space, Layout, Result, Spin } from 'antd'
import { SendOutlined, CodeSandboxOutlined, EditOutlined } from '@ant-design/icons'
import { Alert, notification } from 'antd';
import generate, { ChatItem } from './../tools/generate';
import ChatList from '../components/ChatList';


export let Icon = () => <Button ><EditOutlined />Коррекция</Button>

export class Component extends React.Component {
    state = {
        loading: false,
        data: [
            { role: 'system', text: 'Ты бот, который переписывает исправленное предложение на русском языке. Не добавляй ничего лишнего.' },
        ] as ChatItem[],
        text: '',
        error: null,
        cancel: null as null | (() => void)
    }

    onSend = async (role: string = 'user') => {

        let startData = JSON.parse(JSON.stringify(this.state.data))
        let startText = this.state.text
        this.state.data.push({ text: '' + this.state.text, role: role })
        this.state.data.push({ text: '', role: 'bot' })
        let data = this.state.data

        let cancels: (() => void)[] = [];
        let element = document.getElementById('chatList')
        let currentScrollHeight = element?.scrollHeight || 0
        let currentHeight = element?.clientHeight || 0
        let scrollTop = element && element.scrollTop || 0
        try {
            this.setState({ loading: true, error: null, data, text: '', cancel: () => cancels.forEach(fn => fn()) }, () => {

                let el = element
                if (el && ((scrollTop + currentHeight) / currentScrollHeight) > 0.95) {
                    el.scrollTo(0, element?.scrollHeight || 0)
                }
            })
            let continueText = async () => {
                let current = data[data.length - 1]
                current.loading = true
                await generate('sandbox', data, cancels, () => this.forceUpdate())

                delete current.loading
            }
            await continueText()

            this.forceUpdate()
        } catch (err) {
            let message = (err as any).message as string
            if (message.indexOf('aborted') > -1) {
                message = ''
            } else {

                notification.error({ message })
            }
            this.setState({ error: message, text: startText, data: startData })
        }
        this.setState({ loading: false, cancel: null })

    }
    render() {
        return (<>
            {this.state.error && <Alert message={this.state.error} type="error" ></Alert>}
            <ChatList data={this.state.data} />
            <Space.Compact style={{ width: '95%', margin: '0px auto 5px' }}>
                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.state.data[this.state.data.length - 1].text = ''
                    this.onSend()
                }}>Перегенерация</Button> : null}

                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.onSend()
                }}>Продолжить</Button> : null}
                {this.state.cancel && <Button style={{ width: '200px', margin: '12px 0px' }} onClick={this.state.cancel}>Стоп</Button>}
            </Space.Compact>
            <div style={{ width: '95%', margin: '0px auto 5px', flexWrap: 'wrap' }}>
                <Input.Search placeholder='Введите запрос' value={this.state.text} loading={this.state.loading}
                    onChange={(e) => this.setState({ text: e.target.value })} onSearch={() => {


                        this.onSend()
                    }} enterButton={<SendOutlined />} />
            </div>
        </>);
    }
}
