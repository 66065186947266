


import React from 'react';
import { Input, Button, Space, Layout, Result, Spin } from 'antd'
import { CarryOutOutlined, SendOutlined } from '@ant-design/icons'
import { Alert, notification } from 'antd';
import generate, { ChatItem } from './../tools/generate'
import ChatList from '../components/ChatList';

export let Icon = () => <Button ><CarryOutOutlined /> Объединение текстов</Button>

export class Component extends React.Component {
    state = {
        loading: false,
        data: [] as ChatItem[],
        text1: '',
        text2: '',
        error: null,
        cancel: null as null | (() => void)
    }

    onSend = async () => {
        let data = this.state.data
        let current = data[data.length - 1]
        let cancels: (() => void)[] = [];
        let element = document.getElementById('chatList')
        let currentScrollHeight = element?.scrollHeight || 0
        let currentHeight = element?.clientHeight || 0
        let scrollTop = element && element.scrollTop || 0
        try {
            this.setState({ loading: true, error: null, data, text1: '', text2: '', cancel: () => cancels.forEach(fn => fn()) }, () => {

                let el = element
                if (el && ((scrollTop + currentHeight) / currentScrollHeight) > 0.95) {
                    el.scrollTo(0, element?.scrollHeight || 0)
                }
            })

            current.loading = true
            await generate('merge', data, cancels, () => this.forceUpdate())

            delete current.loading
            this.forceUpdate()
        } catch (err) {
            delete current.loading
            let message = (err as any).message as string
            if (message.indexOf('aborted') > -1) {
                message = ''
            } else {

                notification.error({ message })
            }
            data.pop()
            let text2 = data[data.length - 1].text

            data.pop()
            let text1 = data[data.length - 1].text

            data.pop()
            this.setState({ error: message, text1, text2 })
        }
        this.setState({ loading: false, cancel: null })

    }
    render() {
        return (<>
            {this.state.error && <Alert message={this.state.error} type="error" ></Alert>}
            <ChatList data={this.state.data} />
            <Space.Compact style={{ width: '95%', margin: '0px auto 5px' }}>
                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.state.data[this.state.data.length - 1].text = ''
                    this.onSend()
                }}>Перегенерация</Button> : null}

                {this.state.data.length ? <Button disabled={this.state.loading} style={{ width: '200px', margin: '12px 0px' }} onClick={() => {
                    this.onSend()
                }}>Продолжить</Button> : null}
                {this.state.cancel && <Button style={{ width: '200px', margin: '12px 0px' }} onClick={this.state.cancel}>Стоп</Button>}
            </Space.Compact>
            <div style={{ width: '95%', margin: '0px auto 5px', flexWrap: 'wrap' }}>
                <Input placeholder='Введите текст 1' value={this.state.text1} onChange={(e) => this.setState({ text1: e.target.value })} />
                <Input placeholder='Введите текст 2' value={this.state.text2} onChange={(e) => this.setState({ text2: e.target.value })} />
                <Button icon={<SendOutlined />} onClick={() => {
                    this.state.data.push({ text: this.state.text1, role: 'Текст1' })
                    this.state.data.push({ text: this.state.text2, role: 'Текст2' })
                    this.state.data.push({ text: '', role: 'bot' })
                    this.onSend()
                }}>Объединить</Button>
            </div>
        </>);
    }
}
